import React, { useEffect, useState } from 'react';
import logo from '../../img/logo.png';
import hexomaticLogo from '../../img/powered-by-hexomatic.svg';
import background1 from '../../img/hexomatic-banner/background-1.png';
import background2 from '../../img/hexomatic-banner/background-2.png';
import promoText from '../../img/hexomatic-banner/promo-text.svg';
import mobileBanner from '../../img/hexomatic-banner/mobile-banner.png';
// import topHeader from '../../img/top-header.svg';
// import topHeaderMobile from '../../img/top-header-mobile.svg';
import mobile from '../../img/mobile.svg';
import tab from '../../img/tab.png';
import full from '../../img/full.png';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faYoutube,
  faTwitter,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import './styles.scss';
import Counter from '../countdown';
import CookieConsentPopup from '../cookie-popup';

export default ({ children, title, metaName, metaContent }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);

  useEffect(() => {
    const width =
      global &&
      global.window &&
      global.window.screen &&
      global.window.screen.width;
    if (width < 1300 && width > 991) {
      setIsMobile(false);
      setIsTab(true);
    }
    if (width < 991) {
      setIsMobile(true);
      setIsTab(false);
    }
  }, []);

  return (
    <div className="page-wrap has-text-centered">
      {/* <img
        src={isMobile ? topHeaderMobile : topHeader}
        alt="header"
        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
        className="cursor-pointer"
        onClick={() => window.open('https://promo.hexomatic.com', '_blank')}
      /> */}
      {/* <img
        src={isMobile ? mobile : isTab ? tab : full}
        alt="header"
        style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
        className="cursor-pointer"
        onClick={() => window.open('https://hexomatic.com', '_blank')}
      /> */}
      <div
        className={`p-0 text-center d-flex flex-wrap justify-content-center cursor-pointer`}
        style={{ background: '#AE13FF' }}
        id="promo-header"
        onClick={() =>
          (window.location.href =
            'https://searchscraper.io/?utm_source=fullpagescreencapture&utm_medium=hellobar')
        }
      >
        <div
          className="d-none d-lg-flex justify-content-center"
          style={{ width: '100%', position: 'relative' }}
        >
          <img
            src={background1}
            alt="illustration1"
            className="illustration1"
          />
          <div className="d-flex justify-content-center position-relative m-auto">
            <img
              src={promoText}
              alt=""
              className="m-auto"
              style={{maxWidth: '720px'}}
            />
          </div>
          <img
            src={background2}
            alt="illustration2"
            className="illustration2"
          />
        </div>
        <div
          className="d-flex d-lg-none justify-content-center"
          style={{ width: '100%' }}
        >
          <div className="d-flex d-md-flex-wrap justify-content-center position-relative">
            <img
              src={mobileBanner}
              alt="holiday sale"
              className="px-2 mr-xl-4"
              style={{ maxHeight: '150px' }}
            />
          </div>
        </div>
      </div>
      <section className="section">
        <Helmet>
          <title>{title}</title>
          <meta name={metaName} content={metaContent} />
          <meta
            property="og:image"
            content={'https://fullpagescreencapture.com/social-share-image.png'}
          />
          <meta
            name="twitter:image"
            content="https://fullpagescreencapture.com/social-share-image.png"
          />

          <html lang="en" />
        </Helmet>
        <div className="title mainDiv">
          <Link to="/" className="subDiv">
            <img
              src={logo}
              alt="Fullpagescreencapture"
              width={40}
              height={48}
              style={{ height: '100%' }}
            />
            <h1 className="title title_text">Full Page Screen Capture</h1>
          </Link>
        </div>
        <div className="content">{children}</div>
      </section>
      <footer className="footer navbar is-block">
        <div className="is-flex-desktop" style={{ paddingBottom: '20px' }}>
          <div className="navbar-start">
            <div className="subtitle is-5">
              <a href="https://hexomatic.com/?utm_source=fullpagescreencapture&utm_medium=footer" target="_blank">
                <div
                  className="tooltip"
                  data-tooltip="Web scraping + AI work automation"
                >
                  <img src={hexomaticLogo} alt="Hexomatic.com" width={147} />
                </div>
              </a>
            </div>
          </div>
          <div className="navbar-end">
            <div className="f_social_icon">
              <a
                className="facebook-icon"
                href="https://www.facebook.com/hexometer"
                target="_blank"
                rel="nofollow"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                className="youtube-icon"
                href="https://www.youtube.com/c/HexactInc"
                target="_blank"
                rel="nofollow"
              >
                <FontAwesomeIcon icon={faYoutube} />
              </a>
              <a
                className="linkedIn-icon"
                href="https://www.linkedin.com/company/hexactinc"
                target="_blank"
                rel="nofollow"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
              <a
                className="twitter-icon"
                href="https://twitter.com/hexactinc"
                target="_blank"
                rel="nofollow"
              >
                <FontAwesomeIcon icon={faTwitter} />
              </a>
            </div>
          </div>
        </div>
        <div
          className="is-flex-desktop footer_bottom"
          style={{ paddingTop: '20px', borderTop: '1px solid #e0e3ef' }}
        >
          <div className="navbar-start">
            <a href="https://hexact.io" className="f_300" target="_blank">
              HEXACT, Inc. &copy; {new Date().getFullYear()}
            </a>
          </div>
          <div className="navbar-end is-block">
            <a href="https://hexometer.com/privacy-policy" target="_blank">
              Privacy Policy
            </a>
            <div className="bar" />
            <a href="https://hexometer.com/cookie-policy" target="_blank">
              Cookie Policy
            </a>
          </div>
        </div>
      </footer>
      <CookieConsentPopup />
    </div>
  );
};
