import React from 'react';
import './styles.scss';


const WebsiteFullScreenshot = ({ url, imgUrl, width, height }) => {
  const imageURL = `https://fullpagescreencapture.com/screen/${imgUrl}.jpg`;
  return (
      <div className="container" style={{ paddingTop: '60px' }}>
        <a href={imageURL} target='_blank'>
          <article className="media card">
            <div className="container">
              <img
                className="image"
                src={imageURL}
                alt={url}
                width={width}
                height={height}
              />
              <div className="middle">
                <a href={imageURL} target='_blank' className="button is-primary">Click to Download</a>
              </div>
            </div>
          </article>
        </a>
      </div>
  );
};

export default WebsiteFullScreenshot;