import React from 'react';
// import hexowatch from '../../img/Hexowatch-LTD.jpeg';
// import hexometer from '../../img/Hexometer-agency.png';
import hexomatic from '../../img/Home-page-illustrations.png';

import './styles.scss';

const DashboardScreen = () => {
  return (
    <div className="container_screen">
      {/* <img className="image_screen" src={hexomatic} alt="Hexomatic.com"
        onClick={() => window.open("https://hexomatic.com/#registration")}/> */}
      {/* {
        //@ts-ignore
        <iframe
          src="https://player.vimeo.com/video/568438640?badge=0&autopause=0&player_id=0&app_id=58479"
          width="600"
          height="338"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          style={{ maxWidth: "100%", margin: "auto" }}
        ></iframe>
      } */}
      {/* <a
        target="_blank"
        className="ltd_btn"
        href="https://hexomatic.com/#registration"
      >
        Get started
      </a> */}
      {/* <div className="middle_screen">
        <a
          href="https://hexometer.com/pricing/agency"
          target="_blank"
          className="button is-primary"
        >
          Get started
        </a>
      </div> */}
    </div>
  );
};

export default DashboardScreen;
