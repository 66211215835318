import React from 'react';
import gif from '../../img/Hexomatic_Translation.gif';
import './styles.scss';

const CTA = () => {
  return (
    <div className="cta-wrapper row mt-4">
      <div className="cta-left-wrapper col-12 col-xl-6">
        <h5 className="cta-title">Capture pages at scale with Hexomatic</h5>
        <p>
          Hexomatic is the no-code, point and click work automation platform.
        </p>
        <p>
          Harness the internet as your own data source, build your own scraping
          bots and leverage ready made automations to delegate time consuming
          tasks and scale your business.
        </p>
        <p>No coding or PhD in programming required.</p>
        <div className="d-flex">
          <a
            className="button is-primary is-outlined is-medium is-rounded cta-btn"
            href="https://hexomatic.com/#registration"
          >
            Get started in minutes with Hexomatic
          </a>
        </div>
      </div>
      <div className="gif-wrapper col-12 col-xl-6">
        <img
          src={gif}
          alt="workflow"
          style={{ maxWidth: '450px', width: '100%', padding: '15px' }}
        />
      </div>
    </div>
  );
};

export default CTA;
