import React, {FC} from 'react';
import './styles.scss';

interface IProps {
  btnText: string;
  outline?: boolean;
  primary?: boolean;
  disabled?: boolean | undefined;
  className?: string;
  onClick?: (e: any) => void;
  width?: string;
  loading?: boolean;
  fullWidth?: boolean;
  success?: boolean;
  secondary?: boolean;
}

const Button: FC<IProps> = ({
  btnText,
  onClick,
  outline,
  primary,
  secondary,
  success,
  disabled,
  className,
  width,
  loading,
  fullWidth,
}) => {
  return (
    <button
      className={`custom-btn btn  ${className ? className : ''} ${outline ? 'outline' : ''} ${
        primary ? 'primary' : ''
      } ${success ? 'success' : ''} ${secondary ? 'secondary' : ''}`}
      onClick={onClick}
      disabled={disabled}
      style={{width: fullWidth ? '100%' : width ? width + 'px' : ''}}
    >
      {!loading ? (
        btnText
      ) : (
        <div className="spinner-border text-light" role="status">
          <span className="sr-only"></span>
        </div>
      )}
    </button>
  );
};
export default Button;
