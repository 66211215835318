import Cookies from 'js-cookie';

const CookieDomains = ['localhost', '.fullpagescreencapture.com'];

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);

export const removeCookiePolicy = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicy = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};


