import React, { FC, useEffect } from 'react';
import Button from '../button';
import './styles.scss';

export type ModalProps = {
  isOpen: boolean;
  modalToggle?: () => void;
  title?: string | JSX.Element;
  // children: React.ReactChild
  modalFooter?: React.ReactElement | null;
  headerCentered?: boolean;
  className?: string;
  btnText?: string;
};

export const Modal: FC<ModalProps> = props => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
  // @ts-ignore

  useEffect(() => {
    if (props.isOpen) {
      document.addEventListener('mousedown', handleClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [props]);

  const handleClick = (event: any) => {
    return props.modalToggle && props.modalToggle();
  };

  return (
    <div
      className={`modal ${props.isOpen ? 'show' : ''} ${
        props.className ? props.className : ''
      }`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          {props.title && (
            <div className="modal-header">
              <h5
                className={`modal-title ${
                  props.headerCentered ? 'm-auto' : ''
                }`}
              >
                {props.title}
              </h5>
            </div>
          )}
          <div
            className={
              props.modalFooter === null ? 'modal-body pb_30' : 'modal-body'
            }
          >
            {props.children}
          </div>
          {props.modalFooter === null ? null : (
            <div
              className="modal-footer"
              style={{ justifyContent: props.headerCentered ? 'center' : '' }}
            >
              {props.modalFooter ? (
                props.modalFooter
              ) : (
                <Button
                  onClick={props.modalToggle}
                  className="btn btn-secondary btn-sm"
                  btnText={props.btnText ? props.btnText : 'Close'}
                  width="230"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
